<template>
  <div class="photo-upload-content">
    <div class="mb-3" style="margin-top: 20px">
      <div class="fs-20 font-weight-bold text-left car-title">
        {{ info.rego ? `[${info.rego}]` : '' }}
        {{ info.year }} {{ info.make.toUpperCase() }}
        {{ info.model.toUpperCase() }}
        {{ info.badge ? info.badge.toUpperCase() : '' }}
        {{ info.edition ? info.edition.toUpperCase() : '' }}
      </div>
      <b-overlay
        id="img-upload-process-modal"
        :show="showProcess"
        z-index="9999999"
        @shown="$refs.imgUploadProcess.focus()"
      >
        <template #overlay>
          <div
            ref="imgUploadProcess"
            class="text-center p-4 bg-success text-light rounded"
          >
            <b-icon icon="cloud-upload" font-scale="4"></b-icon>
            <div class="mb-3">{{ imgProcessText }}</div>
            <b-progress
              min="0"
              :max="processMaxWaitTime"
              :value="processAt"
              variant="primary"
              height="3px"
              class="mx-n4 rounded-0"
            ></b-progress>
          </div>
        </template>
        <div class="photo-upload rounded-2 mb-4">
          <div :class="isMobile ? 'px-2 pb-5 pt-2' : 'px-5 pb-5 pt-2'">
            <div class="photo-description px-4 font-weight-bold">
              The more photos you add, the easier it is to sell your car. For best results, we recommend adding at least 15 photos of your car.
              <div class="mt-1">
                <!-- <router-link class="text-underline font-italic font-weight-bold" style="color: #0AB76D;" :to="{ name: 'photo-guide' }" target="_blank">Tips for taking great photos</router-link> -->
                <a class="text-underline font-italic font-weight-bold" style="color: #0AB76D;" :href="photoGuideLink.fromLandingPage" target="_blank">Tips for taking great photos</a>
              </div>
            </div>
            <div 
              class="drop-area position-relative d-flex flex-column align-items-center"
              @drop="drop"
              @dragenter="() => (onDropArea = true)"
              @dragleave="dragleave"
              @dragover="dragoverArea"
            >
              <b-alert variant="danger" dismissible fade :show="showImgSizeErr" @dismissed="showImgSizeErr=false">
                <div class="alert-container" v-if="errImgSize.length > 0">
                  <div class="row d-flex align-items-center">
                    <div class="align-seft-start" :class="isMobile ? 'col' : 'col-auto'">
                      <custom-icon name="danger" width="100%" :m0="true" />
                    </div>
                    <div class="col min-w-222">
                      One or more photos were NOT added. Please upload images that are less than 20MB.
                    </div>
                  </div>
                </div>
              </b-alert>
              <b-alert variant="danger" dismissible fade :show="showImgTypeErr" @dismissed="showImgTypeErr=false">
                <div class="alert-container" v-if="errImgType.length > 0">
                  <div class="row d-flex align-items-center">
                    <div class="align-seft-start" :class="isMobile ? 'col' : 'col-auto'">
                      <custom-icon name="danger" width="100%" :m0="true" />
                    </div>
                    <div class="col min-w-222">
                      One or more photos were NOT added. Please upload images in the following file types: jpeg, png, tiff.
                    </div>
                  </div>
                </div>
              </b-alert>
              <b-alert variant="danger" dismissible fade :show="showMaxLengthErr" @dismissed="showMaxLengthErr=false">
                <div class="alert-container">
                  <div class="row d-flex align-items-center">
                    <div class="align-seft-start" :class="isMobile ? 'col' : 'col-auto'">
                      <custom-icon name="danger" width="100%" :m0="true" />
                    </div>
                    <div class="col min-w-222">
                      One or more photos were NOT added. Please upload up to {{ maxLength }} images.
                    </div>
                  </div>
                </div>
              </b-alert>
              <input
                type="file"
                id="drag-files"
                name="files"
                multiple
                accept="image/*"
                @click="doNothing"
                v-if="!isMobile"
              />
              <div class="photo-div" v-bind:class="{ onDrop: onDropArea && !dragging }">
                <span
                  class="upload-text"
                  v-bind:class="{ 'drop-text': onDropArea && !dragging }"
                  v-if="!isMobile"
                >
                  Drop files here to upload...
                </span>
                <ol class="photo-list wide-list p-0" data-max-empty-containers="6" ref="drop">
                  <draggable
                    @end="dragging = false"
                    v-model="uploadImage"
                    :options="{ delay: isMobile ? 100 : 0 }"
                    @start="dragging = true"
                    draggable=".item"
                  >
                    <li
                      v-for="(i, index) in uploadImage"
                      class="m-1 item"
                      :class="[index === 0 ? 'li-cover-photo' : '', i.err ? 'uploaded-fail' : '']"
                      :key="i.id"
                      style="position: relative"
                    >
                      <div
                        class="image-container"
                        title="Drag image to change order"
                      >
                        <b-overlay
                          :show="i.onUpload"
                          no-wrap
                          spinner-variant="success"
                          :opacity="0.5"
                        ></b-overlay>
                        <img :class="i.err ? 'img-upload-fail' : ''" :data-img-id="i.id" width="100%" :src="i.url" @contextmenu="(e) => doNothing(e)" />
                        <div v-if="i.err" class="upload-fail-text d-flex align-items-center">
                          <img src="@/assets/images/icons/ic_upload_fail.svg" class="upload-fail-icon" alt="">
                          <p class="font-weight-thin">Image upload failed</p>
                        </div>
                        <!-- <div v-if="index !== 0" class="image-title">
                          <b class="font-weight-thin">{{ i.type }} {{ i.title }}</b>
                        </div> -->
                        <b-progress
                          v-if="i.progress && i.progress !== 100"
                          class="img-upload-progress"
                          :value="i.progress"
                          variant="success"
                          striped
                        ></b-progress>
                        <div class="edit-options-list" v-show="!i.onUpload" v-if="!i.err">
                          <v-touch>
                            <custom-icon
                              name="tick"
                              class="edit-photo left top"
                              width="100%"
                              :m0="true"
                            />
                          </v-touch>
                          <v-touch v-on:tap="deleteImg(i.filename, i.key)">
                            <custom-icon
                              name="delete"
                              class="edit-photo right"
                              width="100%"
                              :m0="true"
                              @click="deleteImg(i.filename, i.key)"
                            />
                          </v-touch>
                          <v-touch>
                            <custom-icon
                              :data-img-id="i"
                              name="roll"
                              class="edit-photo left bottom-left"
                              width="100%"
                              @click="rotate(i)"
                            />
                          </v-touch>
                        </div>
                      </div>
                    </li>
                    <li
                      class="m-1 upload-item"
                      :class="[
                        uploadImage.length === 0 ? 'li-cover-photo' : '',
                      ]"
                      style="position: relative"
                      v-if="uploadImage.length < maxLength"
                    >
                      <div
                        class="image-input-wrapper"
                        style="width: 100%; height: 100%"
                        @click="isMobile && !isSpecialDeviceBrowser ? initImgUpload() : initImgUploadFile()"
                      >
                        <input
                          ref="fileinput"
                          type="file"
                          id="files"
                          name="files"
                          style="display: none"
                          multiple
                          @change="uploadPhoto"
                          v-if="!imageDeleting"
                          accept="image/*"
                        />
                        <div
                          class="cover-text-wrapper"
                          style="
                            position: absolute;
                            top: 72%;
                            left: 50%;
                            margin: 0 !important;
                            padding: 0 !important;
                            transform: translate(-50%, -50%);
                            width: 100%;
                          "
                        >
                          <button type="button" class="photo-text">
                            {{isMobile && !isSpecialDeviceBrowser ? 'ADD/TAKE PHOTOS' : 'ADD PHOTOS'}}
                          </button>
                          <div
                            class="fs-14 cover-description-text font-weight-thin"
                          >
                            Supports: jpeg, png, tiff
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      class="m-1 upload-item-no-border"
                      v-for="(item, index) in notUploadItems"
                      :key="`not-img-${item}`"
                      @click="isMobile && !isSpecialDeviceBrowser ? initImgUpload() : initImgUploadFile()"
                      :style="{display: uploadImage.length >= 1 || !isMobile ? 'block' : 'none'}"
                    >
                      <div
                        class="image-input-wrapper"
                        style="width: 100%; height: 100%"
                        @click="isMobile && !isSpecialDeviceBrowser ? initImgUpload() : initImgUploadFile()"
                        v-if="index === 0 && notUploadItems === 6"
                      ></div>
                    </li>
                  </draggable>
                </ol>
              </div>
            </div>
            <div
              style="width: 100%; display: flex; justify-content: flex-end"
              class="px-3 pb-2"
            >
            </div>
            <div class="nudge">
              <b-alert :show="showNudge" dismissible class="alert-heading px-4" :class="isMobile && 'shadow-lg'">
                <p class="heading"><img src="@/assets/images/icons/ic_bulblight.png" :width="isMobile ? '10px' : '12px'"> QUICK TIP</p>
                <p>Don't forget to add photos of your odometer, tyres and any damage.</p>
              </b-alert>
            </div>
            <FeedbackMessage v-if="showFeedbackMessage" :photosNeeded="photosNeeded" />
            <div style="height: 30px;"></div>
            <btn-group
              class=""
              @onOk="next"
              @onCancel="goBack"
              @onSkip="goNext"
              :okBtnDisable="uploadImage.length >= minLength && !isHavingUploadIssue"
              :disableNext="uploadImage.length < minLength || onUpload || isHavingUploadIssue"
              :disableBack="onUpload"
              :disableSkip="uploadImage.length"
              :query=this.$route.query
              :isMobile=isMobile
              :customClasses="`${btnGroupPadding} skip-able`"
              :isHavingUploadIssue="isHavingUploadIssue"
            />
            <div v-if="isMobile" class="bottom-btns"></div>
          </div>
        </div>
      </b-overlay>
    </div>
    <b-modal id="init-upload-modal" hide-footer modal-class="modal-init-upload">
      <template #modal-header>
        <b-button
          type="button"
          aria-label="Close"
          variant="outline-light"
          pill
          style="border: 0; font-size: 25px; position: absolute; right: 0"
          @click="!isAccessingCamera && $bvModal.hide('init-upload-modal')"
        >
          <b-icon icon="x-circle"></b-icon>
        </b-button>
      </template>
      <div class="d-block text-center">
        <div class="upload-option-wrapper">
          <b-overlay
            :show="isAccessingCamera"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
          >
            <div
              class="upload-option upload-camera"
              @click="initImgUploadCamera"
            >
              <div>
                <b-icon class="option-icon" icon="camera-fill"></b-icon>
              </div>
              <div><b>Guided camera mode</b></div>
            </div>
          </b-overlay>

          <div
            class="upload-option upload-file"
            @click="!isAccessingCamera && initImgUploadFile()"
          >
            <div><b-icon class="option-icon" icon="images"></b-icon></div>
            <div><b>Folder</b></div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      hide-header
      hide-footer
      id="camera-modal"
      modal-class="camera-modal"
      ref="cameraModal"
    >

      <BlockScreen class="display-when-portrait" :closeAnyWay="closeBlockScreen" :close="closeCamera"></BlockScreen>

      <div class="camera-screen">
        <video
          ref="cameraView"
          class="camera-view"
          autoplay
          playsinline
          muted
        ></video>
        <div v-if="showCameraOption" class="confirm-option-wrapper d-flex justify-content-center text-center">
          <div
            class="cursor-pointer confirm-option take-again"
            @click="takePhotoAgain"
          >
            <b class="confirm-text">Try Again</b>
            <b-icon icon="arrow-repeat" class="confirm-icon"></b-icon>
          </div>
          <b-overlay
            :show="isSavingCameraPhoto"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block overlay"
          >
            <div
              class="cursor-pointer confirm-option use-this-photo"
              @click="useThisPhoto"
            >
              <b class="confirm-text">Use This Photo</b>
              <b-icon icon="check" class="confirm-icon"></b-icon>
            </div>
          </b-overlay>
        </div>
        <div v-if="showCameraOption && isClosing" class="confirm-option-wrapper d-flex justify-content-center text-center">
          <div
            class="cursor-pointer confirm-option take-again"
            @click="discardAndExit"
          >
            <b class="confirm-text">Discard Photos</b>
            <!-- <b-icon icon="arrow-repeat" class="confirm-icon"></b-icon> -->
          </div>
          <b-overlay
            :show="isSavingCameraPhoto"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block overlay"
          >
            <div
              class="cursor-pointer confirm-option use-this-photo"
              @click="saveAndExit"
            >
              <b class="confirm-text">Save</b>
              <!-- <b-icon icon="check" class="confirm-icon"></b-icon> -->
            </div>
          </b-overlay>
        </div>

        <Tooltips v-if="showHelpGuide"/>
        
        <div v-if="currentCapturePhoto.frame" id="camera-outlay">
          <img
            :src="require(`@/assets/car-frames/${currentCapturePhoto.frame}`)"
            alt=""
          />
        </div>
        <canvas ref="cameraOutput" id="camera-output" @click="closeDropdown"></canvas>
        <div class="function-section">
          <custom-icon name="capture" class="shoot-btn" width="100%" :m0="true" @click="!isSavingCameraPhoto && takePhoto()"/>
          <div class="button-wrapper">
            <div class="under-btn">
              <b-button
                class="body-type-btn"
                @click="!isSavingCameraPhoto && !showCameraOption && toggleBodyTypePanel()"
              >
                <custom-icon name="body_type" width="100%" :m0="true"/>
              </b-button>
              <b-button
                class="help-btn"
                :class="{ active: showHelpGuide }"
                @click="!isSavingCameraPhoto && !showCameraOption && openHelpGuide()"
              >
                <b-icon class="shoot-btn-icon" icon="question"></b-icon
              ></b-button>
            </div>
            <div class="upper-btn">
              <b-button
                class="close-btn"
                @click="!isSavingCameraPhoto && !showCameraOption ? exitCamera() : enableExitConfirmDialog()"
              >
                <b-icon v-if="this.uploadImage.length >= 1" icon="check2" scale="2" ></b-icon>
                <custom-icon v-else name="close" width="100%" :m0="true"/>
              </b-button>
            </div>
          </div>
        </div>
        <div class="body-type-dropdown" v-if="showBodyTypePanel">
          <ul>
            <li v-for="(carBodyType, index) in bodyTypes" :key="index">
              <div class="option" @click="updateBodyType(carBodyType)">
                <div class="body-type-name">
                  {{ carBodyType }}
                </div>
                <div class="body-type-status">
                  <b-icon v-if="carBodyType.toLowerCase() === curBodyType.toLowerCase()" icon="check" scale="2"></b-icon>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="photo-frame-wrapper">
          <div class="photo-frame-dropdown">
            <div class="photo-image-list-wrapper" v-if="showFrameSelection">
              <ul>
                <li v-for="(photo, index) in photoOrdersFiltered" :key="index"  @click="setCurrentPhoto(index)">
                  <div class="option">
                    <div class="frame-type">
                      {{ photo.type }}
                    </div>
                    <div class="frame-name">
                      {{ photo.title }}
                    </div>
                    <div class="frame-status">
                      <b-icon v-if="photo.haveImg" icon="check" scale="2"></b-icon>
                      <b-icon v-else icon="dot" scale="3"></b-icon>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="photo-frame-header">
              <div class="screenshot-title" v-show="showTitle">{{ getCurrentCapturePhotoFrameTitle() }}</div>
              <div class="select-angle-btn" @click="!isSavingCameraPhoto && !showCameraOption && openFrameSelection()">
                SELECT 
                <br>
                ANGLE
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <custom-dialog
      :isDialogOpen="isDialogPhotoUploadIssueOpen"
      @close="closeDialog"
      @skip="closeDialog"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import PhotoUploadService from "@/services/photo-upload";
import mixin from "@/mixins/common";
import { promisePool } from "@/helper/promise-pool";
import imageCompression from 'browser-image-compression';
import { Fullscreen } from "@/helper/fullscreen";
import { 
  MAX_FILE_SIZE, 
  MAX_UPLOAD_FILE_SIZE, 
  EXTENSIONS, 
  socialNetworkBrowser,
  browserList,
} from "@/helper/constant";
import Jimp from 'jimp';
import heicConvert from "heic-convert";
import { logError } from "@/helper/utils";

export default {
  name: "Index",
  mixins: [mixin],
  components: {
    CustomIcon: () => import("../../components/common/CustomIcon"),
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    Tooltips: () => import("../../components/photo-upload/Tooltips.vue"),
    BlockScreen: () => import("../../components/photo-upload/BlockScreen.vue"),
    draggable,
    FeedbackMessage: () => import("../../components/common/FeedbackMessage.vue"),
    CustomDialog: () => import("../../components/common/CustomDialog"),
  },
  data() {
    return {
      uploadImage: [],
      newFiles: [],
      onDrop: false,
      dragCounter: 0,
      onDropArea: false,
      max: MAX_FILE_SIZE,
      errImgSize: [],
      maxLength: 30,
      errImgType: [],
      minLength: 1,
      onUpload: false,
      imgProcessText: "Processing...",
      showProcess: false,
      processAt: 0,
      processMaxWaitTime: 120000, // 2 min
      showCameraOption: false,
      curBodyType: "Sedan",
      curFrame: "",
      isAccessingCamera: false,
      isSavingCameraPhoto: false,
      cameraStream: null,
      cameraConstraints: {
        video: {
          facingMode: "environment",
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
        audio: false,
      },
      showImgSizeErr: false,
      showImgTypeErr: false,
      showMaxLengthErr: false,
      showHelpGuide: false,
      showBodyTypePanel: false,
      showFrameSelection: false,
      isClosing: false,
      checkingImg: {
        // exteriorCoverShot: false,
        exteriorFront: false,
        exteriorFrontSideL: false,
        exteriorSideL: false,
        exteriorRearSideL: false,
        exteriorRear: false,
        exteriorRearSideR: false,
        exteriorSideR: false,
        exteriorFrontSideR: false,
        exteriorTyre: false,
        exteriorRoof: false,
        interiorMeter: false,
        interiorDashboard: false,
        interiorSteeringWheel: false,
        interiorFrontSeat: false,
        interiorBackSeat: false,
        interiorThirdRowSeating: false,
        interiorBoot: false,
        interiorKey: false,
        interiorOwnerManual: false,
        interiorServiceHistory: false,
        interiorOther: false,
      },
      imageDeleting: false,
      showTitle: true,
      isLandscape: false,
      isMobile: true,
      dragging: false,
      isSpecialDeviceBrowser: false,
      isDialogPhotoUploadIssueOpen: false,
    };
  },
  computed: {
    ...mapState({
      steps: (state) => state.common.steps,
      info: (state) => state.info.curVehicleInfo,
      bodyTypes: (state) => state.info.bodyTypes,
      stateImgs: (state) => state.photo.uploadImage,
      photoGuideLink: (state) => state.commonVariables.photoGuideLink,
    }),
    ...mapGetters({
      hasGotQuoteResponse: "hasGotQuoteResponse",
    }),
    notUploadItems: function () {
      return this.isMobile
        ? this.uploadImage.length < 6
          ? 6 - this.uploadImage.length
          : 0
        : this.uploadImage.length < 9
        ? 9 - this.uploadImage.length
        : 0;
    },
    currentCapturePhoto() {
      return this.curFrame;
    },
    currentUploadPhoto() {
      return this.photoOrdersFiltered[this.uploadImage.length] ?? {};
    },
    curBodyTypeFormat: function() {
      let string = this.curBodyType.toLowerCase();
      if (this.curBodyType === 'People mover/Van'){
        return string = 'Mover';
      } else {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },
    photoOrders() {
      return [
        // {
        //   frame: `Exterior/${this.curBodyType}/${this.curBodyType}_Front_Side_Left_Transparent.svg`,
        //   title: "COVER SHOT",
        //   type: "EXTERIOR",
        //   name: "exterior cover shot",
        //   key: "exteriorCoverShot",
        //   haveImg: this.checkingImg.exteriorCoverShot,
        // },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Front.svg`,
          title: "FRONT",
          type: "EXTERIOR",
          name: "exterior front",
          key: "exteriorFront",
          haveImg: this.checkingImg.exteriorFront,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Front_Side_Left_Transparent.svg`,
          title: "3/4 FRONT (LEFT)",
          type: "EXTERIOR",
          name: "exterior front side l",
          key: "exteriorFrontSideL",
          haveImg: this.checkingImg.exteriorFrontSideL,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Side_Left.svg`,
          title: "SIDE (LEFT)",
          type: "EXTERIOR",
          name: "exterior side l",
          key: "exteriorSideL",
          haveImg: this.checkingImg.exteriorSideL,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Back_Side_Left.svg`,
          title: "3/4 REAR (LEFT)",
          type: "EXTERIOR",
          name: "exterior rear side l",
          key: "exteriorRearSideL",
          haveImg: this.checkingImg.exteriorRearSideL,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Back.svg`,
          title: "REAR",
          type: "EXTERIOR",
          name: "exterior rear",
          key: "exteriorRear",
          haveImg: this.checkingImg.exteriorRear,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Back_Side_Right.svg`,
          title: "3/4 REAR (RIGHT)",
          type: "EXTERIOR",
          name: "exterior rear side r",
          key: "exteriorRearSideR",
          haveImg: this.checkingImg.exteriorRearSideR,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Side_Right.svg`,
          title: "SIDE (RIGHT)",
          type: "EXTERIOR",
          name: "exterior side r",
          key: "exteriorSideR",
          haveImg: this.checkingImg.exteriorSideR,
        },
        {
          frame: `Exterior/${this.curBodyTypeFormat}/${this.curBodyTypeFormat}_Front_Side_Right_Transparent.svg`,
          title: "3/4 FRONT (RIGHT)",
          type: "EXTERIOR",
          name: "exterior front side r",
          key: "exteriorFrontSideR",
          haveImg: this.checkingImg.exteriorFrontSideR,
        },
        {
          frame: "Exterior/Car_tyre.svg",
          title: "CAR TYRE",
          type: "EXTERIOR",
          name: "exterior tyre",
          notSendToQuote: true,
          key: "exteriorTyre",
          haveImg: this.checkingImg.exteriorTyre,
        },
        {
          frame: "Exterior/Car_roof.svg",
          title: "CAR ROOF",
          type: "EXTERIOR",
          name: "exterior roof",
          notSendToQuote: true,
          key: "exteriorRoof",
          haveImg: this.checkingImg.exteriorRoof,
        },
        {
          frame: "Interior/ODOMETER.svg",
          title: "ODOMETER",
          type: "INTERIOR",
          name: "interior meter",
          notSendToQuote: true,
          key: "interiorMeter",
          haveImg: this.checkingImg.interiorMeter,
        },
        {
          frame: "Interior/DASHBOARD.svg",
          title: "DASHBOARD",
          type: "INTERIOR",
          name: "interior dash",
          notSendToQuote: true,
          key: "interiorDashboard",
          haveImg: this.checkingImg.interiorDashboard,
        },
        {
          frame: "Interior/Steering_wheel.svg",
          title: "STEERING WHEEL",
          type: "INTERIOR",
          name: "interior steering wheel",
          notSendToQuote: true,
          key: "interiorSteeringWheel",
          haveImg: this.checkingImg.interiorSteeringWheel,
        },
        {
          frame: "Interior/FRONT SEAT.svg",
          title: "FRONT SEAT",
          type: "INTERIOR",
          name: "interior front seat",
          notSendToQuote: true,
          key: "interiorFrontSeat",
          haveImg: this.checkingImg.interiorFrontSeat,
        },
        {
          frame: "Interior/BACK SEAT.svg",
          title: "BACK SEAT",
          type: "INTERIOR",
          name: "interior back seat",
          notSendToQuote: true,
          key: "interiorBackSeat",
          haveImg: this.checkingImg.interiorBackSeat,
        },
        {
          frame: "Interior/3RD ROW SEAT.svg",
          title: "3RD ROW SEAT",
          type: "INTERIOR",
          name: "interior third row seating",
          notSendToQuote: true,
          key: "interiorThirdRowSeating",
          haveImg: this.checkingImg.interiorThirdRowSeating,
        },
        {
          frame: `Interior/Boot - ${this.curBootFrame}.svg`,
          title: "BOOT",
          type: "INTERIOR",
          name: "boot",
          notSendToQuote: true,
          key: "interiorBoot",
          haveImg: this.checkingImg.interiorBoot,
        },
        {
          frame: "Interior/KEYS.svg",
          title: "KEYS",
          type: "INTERIOR",
          name: "key",
          notSendToQuote: true,
          key: "interiorKey",
          haveImg: this.checkingImg.interiorKey,
        },
        {
          frame: "",
          title: "OWNER'S MANUAL",
          type: "INTERIOR",
          name: "interior owner's manual",
          notSendToQuote: true,
          key: "interiorOwnerManual",
          haveImg: this.checkingImg.interiorOwnerManual,
        },
        {
          frame: "",
          title: "SERVICE HISTORY",
          type: "INTERIOR",
          name: "interior service history",
          notSendToQuote: true,
          key: "interiorServiceHistory",
          haveImg: this.checkingImg.interiorServiceHistory,
        },
        {
          // frame: "Interior/Other.svg",
          frame: "",
          title: "OTHER",
          type: "INTERIOR",
          name: "interior other",
          notSendToQuote: true,
          key: "interiorOther",
          haveImg: this.checkingImg.interiorOther,
        },
      ]
    },
    bodyTypes() {
      return ['Sedan', 'SUV', 'Hatchback', 'Ute', 'Wagon', 'Coupe', 'Convertible', 'People mover/Van'];
    },
    photoOrdersFiltered() {
      const filteredType = ['SUV', 'People mover/Van'];
      let tempFilteredList = [];
      if(!filteredType.includes(this.curBodyType)) {
        this.photoOrders.forEach((photo) => {
          photo.key !== 'interiorThirdRowSeating' && tempFilteredList.push(photo);
        })
      } else {
        tempFilteredList = [...this.photoOrders];
      }
      return tempFilteredList;
    },
    curBootFrame() {
      let curBootSVGType = '';
      if (this.curBodyType === 'Sedan' || this.curBodyType === 'Convertible') {
        curBootSVGType = 'Sedan';
      } else {
        curBootSVGType = 'SUV NEW';
      }
      return curBootSVGType || "";
    },
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 900px)");
    },
    showNudge() {
      return this.uploadImage.length === 0 || this.uploadImage.length >= 10
    },
    isUploadImage() {
      return this.uploadImage.length > 0;
    },
    showFeedbackMessage() {
      return this.uploadImage.length >= 1 && !this.showNudge
    },
    photosNeeded() {
      return 10 - this.uploadImage.length;
    },
    btnGroupPadding() {
      if (!this.isMobile) {
        if (this.uploadImage.length === 0) return 'custom-padding-3';
        else if (this.uploadImage.length < 10) return 'custom-padding-2';
        else return '';
      }
      return '';
    },
    isHavingUploadIssue() {
      return this.uploadImage.every(image => Object.hasOwnProperty.call(image, "err"));
    },
  },
  methods: {
    ...mapActions({
      evaluateImages: "evaluateImages",
      pollQuoteResponse: "pollQuoteResponse",
    }),
    useThisPhoto() {
      this.isSavingCameraPhoto = true;
      this.$refs.cameraOutput.toBlob(
        async (blob) => {
          const imgId = this.$uuid.v4();
          this.uploadImage = [
            ...this.uploadImage,
            {
              id: imgId,
              filename: this.currentCapturePhoto.name,
              name: this.currentCapturePhoto.name,
              title: this.currentCapturePhoto.title,
              type: this.currentCapturePhoto.type,
              key: this.currentCapturePhoto.key,
              notSendToQuote: this.currentCapturePhoto.notSendToQuote,
              url: null,
              onUpload: true,
              progress: 0,
            },
          ];
          this.handleImgUpload(blob, imgId);
          this.isSavingCameraPhoto = false;
          this.showCameraOption = false;
          this.checkingImg[this.currentCapturePhoto.key] = true;
          this.nextFrame();
          this.clearVideoOutput();
          if (this.uploadImage.length === this.maxLength) {
            this.$bvModal.hide("camera-modal");
            this.cameraStream.getTracks().forEach((track) => track.stop());
          }
        },
        "image/jpeg",
        1
      );
      this.$store.dispatch("setTakePhotoWithGuidedCamera", true);
    },
    takePhotoAgain() {
      this.clearVideoOutput();
      this.showCameraOption = false;
    },
    takePhoto() {
      this.showBodyTypePanel = false;
      this.showHelpGuide = false;
      this.showFrameSelection = false;
      const { w, h } = this.getVideoSize(this.$refs.cameraView);
      this.$refs.cameraOutput.width = w;
      this.$refs.cameraOutput.height = h;
      this.$refs.cameraOutput
        .getContext("2d")
        .drawImage(this.$refs.cameraView, 0, 0);
      this.showCameraOption = true;
    },
    exitCamera() {
      this.$bvModal.hide("camera-modal");
      this.clearVideoOutput();
      this.cameraStream.getTracks().forEach((track) => track.stop());
      this.toggleChatSection();
      this.closeDropdown();
    },
    initImgUpload() {
      !this.imageDeleting && this.$bvModal.show("init-upload-modal");
    },
    initImgUploadFile() {
      this.$refs.fileinput.click();
      this.$bvModal.hide("init-upload-modal");
    },
    async initImgUploadCamera() {
      try {
        this.isAccessingCamera = true;
        this.cameraStream = await navigator.mediaDevices.getUserMedia(
          this.cameraConstraints
        );
        this.isAccessingCamera = false;
        this.$bvModal.hide("init-upload-modal");
        this.$bvModal.show("camera-modal");
        this.$nextTick(() => {
          new Fullscreen(document.querySelector("#camera-modal")).toggle();
          this.$refs.cameraView.srcObject = this.cameraStream;
        });
        const chatSection = document.getElementsByTagName('mmgp-sms-chat-widget')[0];
        if (chatSection) {
          chatSection.style.display = "none";
        }
      } catch (err) {
        this.isAccessingCamera = false;
        this.$toast.error("Unable to access camera");
        logError(`Unable to access camera: ${err}`);
      }
    },
    getVideoSize(ele) {
      const w = ele.videoWidth;
      const h = ele.videoHeight;
      return { w, h };
    },
    clearVideoOutput() {
      this.$refs.cameraOutput
        .getContext("2d")
        .clearRect(
          0,
          0,
          this.$refs.cameraOutput.width,
          this.$refs.cameraOutput.height
        );
    },
    goBack() {
      this.$store.dispatch("setUploadedImages", this.uploadImage);
      this.$router.push({ name: "vehicle-info", query: this.$route.query });
    },
    async next() {
      this.$store.dispatch("setProgress", 96);
      this.$store.dispatch("setUploadedImages", this.uploadImage);
      this.$store.dispatch("setSendPhotoLater", false);
      this.$router.push({ name: "customer-detail", query: this.$route.query });
    },
    goNext() {
      this.$store.dispatch("setProgress", 96);
      if (!this.onUpload) {
        this.$store.dispatch("setUploadedImages", this.uploadImage);
        this.$store.dispatch("clearSendPhotoLater");
        this.$router.push({ name: "customer-detail", query: this.$route.query });
      }
    },
    async rotate(i) {
      this.onUpload = true;
      this.uploadImage = this.uploadImage.map((item) => {
        if (item.id !== i.id) return item;
        else {
          return {
            ...item,
            onUpload: true,
          };
        }
      });
      try {
        const newImg = await PhotoUploadService.rotate(i);
        this.uploadImage = this.uploadImage.map((item) => {
          if (item.id !== i.id) return item;
          else {
            return {
              ...item,
              ...newImg,
              id: i.id,
              onUpload: false,
            };
          }
        });
      } catch (err) {
        this.$toast.error("Too many requests");
        logError(`Too many requests: ${err}`);
      }
      this.onUpload = false;
    },
    convertImageMimeType: async function(imageFile, predominantColorsDetected, checkers) {
      try {
        checkers.push(() =>
          this.checkPredominantColors(
            imageFile,
            "user's uploaded file",
            predominantColorsDetected
          )
        );
        const fileExt = (imageFile.name || "").split(".").pop();
        if (/(tiff|tif)/gi.test(fileExt)) {
          const fileBlobToUrl = URL.createObjectURL(imageFile);
          const jimpImage = await Jimp.read(fileBlobToUrl);
          const buffer = await jimpImage.getBufferAsync(Jimp.MIME_PNG);
          const jimpImageBlob = new Blob([buffer], { type: Jimp.MIME_PNG });
          checkers.push(() =>
            this.checkPredominantColors(
              jimpImageBlob,
              "tiff|tif converter",
              predominantColorsDetected
            )
          );
          return jimpImageBlob;
        } else if (/(heic)/gi.test(fileExt)) {
          const blob = await imageFile.arrayBuffer();
          const buffer = Buffer.from(blob);
          const jpgBuffer = await heicConvert({
            buffer,
            format: "JPEG",
            quality: 1,
          });
          const outputFile = new File([jpgBuffer], "output.jpg", {
            type: "image/jpeg",
          });
          checkers.push(() =>
            this.checkPredominantColors(
              outputFile,
              "heic converter",
              predominantColorsDetected
            )
          );
          return outputFile;
        }
        return imageFile;
      } catch (err) {
        logError(`convertImageMimeType error: ${err}`);
        return imageFile;
      }
    },
    handleImgUpload: async function(file, imgId) {
      try {
        let img;
        let predominantColorsDetected = {};
        const checkers = [];
        const imageFile = await this.convertImageMimeType(
          file,
          predominantColorsDetected,
          checkers
        );
        if (imageFile.size > MAX_UPLOAD_FILE_SIZE) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            maxIteration: 20,
            fileType: Jimp.MIME_JPEG,
          };
          img = await imageCompression(imageFile, options);
          checkers.push(() =>
            this.checkPredominantColors(
              img,
              "image resizer",
              predominantColorsDetected
            )
          );
        } else {
          img = imageFile;
        }
        const blobToUrl = URL.createObjectURL(img);
        this.uploadImage = this.uploadImage.map((stateImge) =>
          stateImge.id === imgId
            ? {
                ...stateImge,
                url: blobToUrl,
              }
            : stateImge
        );
        let formData = new FormData();
        formData.append("image", img);
        formData.append(
          "predominantColorsDetected",
          Object.values(predominantColorsDetected).includes(true)
        );
        const uploadedImgData = await PhotoUploadService.uploadImage(
          formData,
          imgId,
          (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.uploadImage = this.uploadImage.map((stateImge) =>
              stateImge.id === imgId
                ? {
                    ...stateImge,
                    progress,
                  }
                : stateImge
            );
          }
        );
        URL.revokeObjectURL(blobToUrl);
        if (
          uploadedImgData?.isPredominantColorsImage &&
          !Object.values(predominantColorsDetected).includes(true)
        ) {
          checkers.push(() =>
            this.logPredominantColorsError(
              "uploaded image in S3",
              uploadedImgData
            )
          );
        }
        this.runCheckersSequentially(checkers);
        this.uploadImage = this.uploadImage
          .filter((img) => {
            return !Object.hasOwnProperty.call(uploadedImgData, "err")
              ? !Object.hasOwnProperty.call(img, "err")
              : img;
          })
          .map((exImg) => {
            return imgId !== exImg.id
              ? exImg
              : {
                  ...exImg,
                  ...uploadedImgData,
                  onUpload: false,
                  progress: 100,
                };
          });
      } catch (err) {
        logError(`handleImgUpload error: ${err}`);
      }
    },
    uploadPhoto: async function (event) {
      var input = event.target;
      const promiseBatch = [];
      this.errImgSize = [];
      this.errImgType = [];
      this.showMaxLengthErr = false;
      this.showImgSizeErr = false;
      this.showImgTypeErr = false;
      var count_file = this.uploadImage.length;
      if (input.files && this.uploadImage.length < this.maxLength) {
        this.onUpload = true;
        Array.from(input.files).forEach((file) => {
          if (!EXTENSIONS.includes(file.name.split('.').pop().toLowerCase())) {
            this.errImgType = [...this.errImgType, file.name];
          } else if (file.size > this.max) {
            this.errImgSize = [...this.errImgSize, file.name];
          } else if (this.uploadImage.length < this.maxLength) {
            const imgId = this.$uuid.v4();
            this.nextFrame();
            this.checkingImg[this.curFrame.key] = true;
            this.uploadImage = [
              ...this.uploadImage,
              {
                id: imgId,
                filename: file.name,
                url: null,
                name: file.name,
                key: this.photoOrdersFiltered[this.uploadImage.length]?.key,
                title: this.photoOrdersFiltered[this.uploadImage.length]?.title,
                type: this.photoOrders[this.uploadImage.length]?.type,
                onUpload: true,
                progress: 0,
              },
            ];
            promiseBatch.push(() => this.handleImgUpload(file, imgId));
          }
          count_file = count_file + 1 ;
        });
        if (this.errImgSize.length > 0) {
          this.showImgSizeErr = true
        } else {
          this.showImgSizeErr = false
        }
        if (this.errImgType.length > 0) {
          this.showImgTypeErr = true
        } else {
          this.showImgTypeErr = false
        }
        await promisePool(promiseBatch, 3);
        this.onUpload = false;
        event.target.value = "";
      }
      if (count_file > this.maxLength) {
        event.target.value = "";
        this.showMaxLengthErr = true
      }
    },
    dragoverArea: function(e) {
      this.doNothing(e);
      this.onDropArea = true;
      if (!this.dragging) this.$refs.drop.style.opacity = 0;
    },
    drop: async function(e) {
      this.onDropArea = false;
      this.onDrop = false;
      this.$refs.drop.style.opacity = 1;
      this.doNothing(e);
      if (this.uploadImage.length === this.maxLength) return;
      const uploadJob = [];
      var count_file = this.uploadImage.length;
      const handleUploadFile = (img) => {
        if (!EXTENSIONS.includes(img.name.split(".").pop().toLowerCase())) {
          this.errImgType = [...this.errImgType, img.name];
        } else if (img.size > this.max) {
          this.errImgSize = [...this.errImgSize, img.name];
        } else if (this.uploadImage.length < this.maxLength) {
          const imgId = this.$uuid.v4();
          this.uploadImage = [
            ...this.uploadImage,
            {
              id: imgId,
              imgname: img.name,
              title: this.currentPhoto?.title,
              url: null,
              onUpload: true,
              progress: 0,
            },
          ];
          uploadJob.push(() => this.handleImgUpload(img, imgId));
        }
      };
      async function getFile(fileEntry) {
        try {
          return new Promise((resolve, reject) =>
            fileEntry.file(resolve, reject)
          );
        } catch (err) {
          logError(`Get file error: ${err}`);
        }
      }
      function traverse_directory(entry) {
        let reader = entry.createReader();
        return new Promise((resolve_directory) => {
          let iteration_attempts = [];
          (function read_entries() {
            reader.readEntries((entries) => {
              if (!entries.length) {
                resolve_directory(Promise.all(iteration_attempts));
              } else {
                iteration_attempts.push(
                  entries.map(async (entry) => {
                    if (entry.isFile) {
                      const file = await getFile(entry);
                      handleUploadFile(file);
                      return file;
                    } else {
                      return traverse_directory(entry);
                    }
                  })
                );
                read_entries();
              }
            });
          })();
        });
      }
      // upload folder
      if (
        this.uploadImage.length < this.maxLength &&
        e.dataTransfer.items.length > 0
      ) {
        this.onUpload = true;
        this.errImgType = [];
        this.errImgSize = [];
        this.showImgSizeErr = false;
        this.showImgTypeErr = false;
        Array.from(e.dataTransfer.items).forEach(async (item, idx) => {
          const entry = item.webkitGetAsEntry();
          if (entry != null) {
            if (entry.isDirectory) {
              traverse_directory(entry).then(async () => {
                await promisePool(uploadJob, 3);
                e.target.value = "";
                if (this.errImgSize.length > 0) {
                  this.showImgSizeErr = true;
                } else {
                  this.showImgSizeErr = false;
                }
                if (this.errImgType.length > 0) {
                  this.showImgTypeErr = true;
                } else {
                  this.showImgTypeErr = false;
                }
              });
            }
            if (entry.isFile) {
              handleUploadFile(e.dataTransfer.files[idx]);
              count_file = count_file + 1;
            }
          }
        });
        await promisePool(uploadJob, 3);
        this.onUpload = false;
        e.target.value = "";
        if (this.errImgSize.length > 0) {
          this.showImgSizeErr = true;
        } else {
          this.showImgSizeErr = false;
        }
        if (this.errImgType.length > 0) {
          this.showImgTypeErr = true;
        } else {
          this.showImgTypeErr = false;
        }
      } else if (e.dataTransfer.items.length > 0) {
        this.showMaxLengthErr = true;
        e.target.value = "";
      }
      if (count_file > this.maxLength) {
        this.showMaxLengthErr = true;
        e.target.value = "";
      }
    },
    dragleave: function(e) {
      this.onDrop = false;
      this.onDropArea = false;
      this.doNothing(e);
      this.$refs.drop.style.opacity = 1;
    },
    doNothing(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    async deleteImg(filename, key) {
      await PhotoUploadService.deteleImg(filename);
      this.uploadImage = await this.uploadImage.filter(
        (item) => item.filename !== filename
      );
      this.imageDeleting = true;
      setTimeout(() => (this.imageDeleting = false), 300);
      this.checkingImg[key] = false;
      if(this.uploadImage.length === 1 || key == "exteriorFront") {
        this.curFrame = this.photoOrdersFiltered[0];
      }
    },
    async deleteAllImages() {
      Promise.all(
        this.uploadImage.map((img) =>
          PhotoUploadService.deteleImg(img.filename)
        )
      );
      for (const [key] of Object.entries(this.checkingImg)) {
        this.checkingImg[key] = false;
      }
      this.uploadImage = [];
      this.curFrame = this.photoOrdersFiltered[0];
      this.$store.dispatch("setProgress", 0);
      this.$store.dispatch("clearImages");
    },
    openHelpGuide() {
      this.showFrameSelection = false;
      this.showBodyTypePanel = false;
      this.showHelpGuide = !this.showHelpGuide;
    },
    toggleBodyTypePanel() {
      this.showHelpGuide = false;
      this.showFrameSelection = false;
      this.showBodyTypePanel = !this.showBodyTypePanel;
    },
    updateBodyType(bodyType) {
      this.curBodyType = bodyType;
      this.showBodyTypePanel = false;
      // Update current Frame
      this.nextFrame();
    },
    closeDropdown() {
      this.showBodyTypePanel = false;
      this.showFrameSelection = false;
      this.showHelpGuide = false;
    },
    openFrameSelection () {
      this.showBodyTypePanel = false;
      this.showFrameSelection = !this.showFrameSelection;
      this.showHelpGuide = false;
    },
    enableExitConfirmDialog() {
      this.isClosing = true;
    },
    closeCamera() {
      this.exitCamera();
      this.showCameraOption = false;
      this.isClosing = false;
      this.toggleChatSection();
    },
    saveAndExit() {
      this.useThisPhoto();
      this.closeCamera();
    },
    discardAndExit() {
      this.deleteAllImages();
      this.closeCamera();
    },
    setCurrentPhoto(index) {
      this.curFrame = this.photoOrdersFiltered[index];
      this.showTitle = false;
      if(this.curBodyType == "SUV" && this.curFrame.key == "interiorBoot"){
       this.curFrame.frame  = "Interior/Boot - SUV NEW.svg";
      }
      this.showTitle = false;
      this.$nextTick(() => {
        this.showTitle = true;
      });
      this.showFrameSelection = false;
    },
    nextFrame() {
      this.showTitle = false;
      if(this.uploadImage.length >= this.photoOrdersFiltered.length) {
        this.curFrame = this.photoOrdersFiltered[this.photoOrdersFiltered.length - 1];
      } else {
        for (let photoFrame of this.photoOrdersFiltered) {
          if (photoFrame.haveImg === false) {
            this.curFrame = photoFrame;
            break;
          }
        }
      }
      this.showTitle = false;
      this.$nextTick(() => {
        this.showTitle = true;
      });
    },
    getCurrentCapturePhotoFrameTitle() {
      const title = this.curFrame.title;
      if (title) {
        return title.toUpperCase();
      }
    },
    getChatSection() {
      return document.getElementsByTagName('mmgp-sms-chat-widget')[0];
    },
    toggleChatSection() {
      const chatSection = this.getChatSection();
      if (chatSection) {
        if (chatSection.style.display === 'none') {
          chatSection.style.display = "block";
        } else {
          chatSection.style.display = "none";
        }
      }
    },
    closeBlockScreen() {
      const blockScreen = document.querySelector('.display-when-portrait');
      if (blockScreen) {
        blockScreen.style.display = "none";
      }
    },
    openDialog() {
      this.isDialogPhotoUploadIssueOpen = true;
    },
    closeDialog() {
      this.isDialogPhotoUploadIssueOpen = false;
    },
    async checkPredominantColors(imageBuffer, action, predominantColorsDetected) {
      try {
        if (!["image/jpg", "image/jpeg", "image/png", "image/tiff"].includes(imageBuffer.type)) {
          return false;
        }
        let formData = new FormData();
        formData.append("image", imageBuffer);
        const checkResult = await PhotoUploadService.detectPredominantColors(formData);
        const isLoggedError = Object.values(predominantColorsDetected).includes(true);
        if (checkResult && !isLoggedError) {
          predominantColorsDetected[action] = true;
          const imageDetails = {
            type: imageBuffer.type,
            size: imageBuffer.size,
          }
          this.logPredominantColorsError(action, imageDetails);
        }
        return checkResult;
      } catch (err) {
        logError(`Check predominant colors error: ${err}`);
        return false;
      }
    },
    logPredominantColorsError(action, imageDetails = {}) {
      const hint = {
        imageDetails,
        rego: this.info.rego,
        state: this.info.location,
      };
      logError(`Predominant colour is detected from "${action}". ${JSON.stringify(hint)}`);
    },
    runCheckersSequentially(checkers) {
      let currentCheckerIndex = 0;
      const runNextChecker = () => {
        if (currentCheckerIndex < checkers.length) {
          // Run the current checker and move to the next one only if it returns false
          checkers[currentCheckerIndex]().then(result => {
            if (result === true) {
              return; // Stop execution of remaining checkers
            }
            currentCheckerIndex++;
            runNextChecker(); // Run the next checker
          });
        }
      };
      runNextChecker(); // Start the sequence
    },
  },
  mounted() {
    this.uploadImage = this.stateImgs;
    this.curBodyType = this.bodyTypes.some(bodyType => bodyType.toLowerCase() === this.info.bodyType.toLowerCase()) ? this.info.bodyType : 'Sedan';
    this.curFrame = this.photoOrdersFiltered[0];

    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;

    if (this.isMobile) {
      const userAgent = navigator.userAgent || window.opera; // Check user use in-app facebook or instagram browser android so can only upload
      const isAndroidSpecialBrowser = userAgent.includes('Android') && socialNetworkBrowser.some(browser => userAgent.includes(browser));
      const isiOSChromeBrowser = (userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes(browserList);

      if (isAndroidSpecialBrowser) {
        this.isSpecialDeviceBrowser = true;
        this.isDialogPhotoUploadIssueOpen = true;
      } else if (isiOSChromeBrowser) {
        this.isSpecialDeviceBrowser = true;
      }
    }

    window.onpopstate = null;
  },
};
</script>

<style lang="scss">
///
.camera-modal {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgb(51, 51, 51);
  }
  .modal-content {
    width: 100%;
    height: 100%;
    background-color: initial;
  }
  .modal-body {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .camera-screen {
    background: white;
    position: relative;
    width: 100%;
    height: 100%;
    .function-section {
      height: 90px;
      width: 100%;
      background: rgba(37, 43, 47, 0.5);
      backdrop-filter: blur(22px);
      z-index: 1700;
      position: fixed;
      bottom: 0;
      left: 0;

      .shoot-btn {
        width: 100px;
        height: 100px;
        position: fixed;
        bottom: 45px;
        left: 50%;
        background: rgba(37, 43, 47, 0.5);
        -webkit-backdrop-filter: blur(22px);
        backdrop-filter: blur(22px);
        border-radius: 50%;
        transform: rotate(90deg) translate(5px, 40px);

        ::before {
          content: '';
          width: 2px;
          height: 50px;
          background: #ffffff;
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -2px;
        }
        ::after {
          content: '';
          border: 1px solid #ffffff;
          width: 1px;
          height: 1000px;
          background: #ffffff;
        }
      }

      .button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        // padding: 0 20px;

        .upper-btn {
          .btn {
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .close-btn {
            border-radius: 50% !important;
            border: 2px solid #ffffff;
            background: rgba(255, 255, 255, 0.25);
            width: 50px;
            height: 50px;
            color: #ffffff;
            transform: rotate(90deg);
          }
        }

        .under-btn {
          display: flex;
          flex-direction: row-reverse;
          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .btn:first-child {
            margin: 10px 0px;
          }
          .btn:last-child {
            margin: 10px;
          }
          .body-type-btn, .help-btn {
            border-radius: 50% !important;
            border: 2px solid #ffffff;
            background: rgba(255, 255, 255, 0.25);
            width: 50px;
            height: 50px;
            transform: rotate(90deg);
          }
          .help-btn {
            color: #ffffff;
            font-size: 32px;
            &.active {
              background: #2BB673;
            }
          }
        }
      }
    }
    #camera-output {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1058;
      object-fit: cover;
    }
    #camera-outlay {
      user-select: none;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 90px);
      z-index: 1057;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: calc(100vh - 120px);
        height: 45%;
        transform: rotate(90deg);
        padding-right: 90px;
        padding-left: 50px;
      }
    }
    .camera-view {
      pointer-events: none;
      user-select: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1056;
      object-fit: cover;
    }
    .body-type-dropdown {
      z-index: 1700;
      position: fixed;
      transform: rotate(90deg) translateX(calc(-50% - 127px));
      top: 50%;
      left: 50px;
      color: #FFFFFF;
      ul {
        background: rgba(37, 43, 47, 0.5);
        border: 2px solid #FFFFFF;
        -webkit-backdrop-filter: blur(19px);
        backdrop-filter: blur(19px);
        border-radius: 22px;
        list-style-type: none;
        padding: 0 !important;
        max-height: 300px;
        overflow-y: auto;
        li {
          background: transparent;
          border-bottom: 1px solid #ffffff;
          height: 40px;
          padding: 10px 20px;
          max-width: 190px;
          &:last-child {
            padding-bottom: 50px;
          }
          .option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .body-type-status {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .photo-frame-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 1060;
      .photo-frame-dropdown {
        transform: translateY(calc(-50% - 60px));
        position: fixed;
        top: 50%;
        right: -3px;
        color: #FFFFFF;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .photo-image-list-wrapper {
          background: rgba(37, 43, 47, 0.5);
          border: 2px solid #FFFFFF;
          -webkit-backdrop-filter: blur(19px);
          backdrop-filter: blur(19px);
          width: 330px;
          height: 330px;
          border-bottom: none;
          transform: rotate(90deg);
          ul {
            list-style-type: none;
            padding: 0 !important;
            margin: 0 !important;
            height: 100%;
            // width: 100%;
            overflow-y: auto;
            li {
              background: transparent;
              border-bottom: 1px solid #ffffff;
              height: 40px;
              padding: 10px;
              width: 326px !important;
              line-height: 16px;
              .option {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .frame-type, .frame-name {
                  font-weight: 700;
                  font-size: 17px;
                  color: #B8B8B8;
                }
                .frame-type {
                  width: 25%;
                }
                .frame-name {
                  color: #ffffff;
                  width: 55%;
                }
                .frame-status {
                  width: 10%;
                }
                @media screen and (max-width: 667px) {
                  .frame-type, .frame-name {
                    font-size: 15px !important;
                  }
                }
              }
            }
          }
        }
        .photo-frame-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          background: rgba(37, 43, 47, 0.5);
          border: 2px solid #FFFFFF;
          -webkit-backdrop-filter: blur(19px);
          backdrop-filter: blur(19px);
          border-radius: 22px 0 0 22px;
          height: 330px;
          text-orientation: mixed;
          -ms-writing-mode: tb-rl;
          writing-mode: tb-rl;
          .screenshot-title, .select-angle-btn {
            font-weight: 700;
            text-align: center;
            color: #FFFFFF;
          }
          .screenshot-title {
            height: 100%;
            padding: 10px 0px;
          }
          .select-angle-btn {
            padding: 20px 0px;
            font-size: 14px;
            line-height: 22px;
            border-top: 2px solid #ffffff;
          }
        }
      }
    }
  }
  .confirm-option-wrapper {
    position: fixed;
    left: -15px;
    z-index: 1200;
    top: 50% !important;
    flex-direction: column;
    transform: translateY(calc(-50% - 55px));
    .confirm-option {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      b {
        color: #fff;
        font-size: 17px;
      }
    }
    .use-this-photo, .take-again {
      background: #252B2F;
      border: 2px solid #FFFFFF;
      border-radius: 20px;
      color: #ffffff;
      text-orientation: mixed;
      writing-mode: vertical-lr;
      height: 170px;
      width: 65px;
      .confirm-text {
        white-space: nowrap;
        margin: 0 0 10px 0;
      }
      .confirm-icon {
        transform: rotate(90deg);
        width: 20px;
        height: 20px;
      }
    }
    .take-again {
      height: 190px;
    }
    .overlay {
      top: -30px;
      .use-this-photo {
        background-color: #05b36d;
      }
    }
  }
}
//
.modal-init-upload {
  .modal-header {
    padding: 10px !important;
    border: 0 !important;
  }
  .modal-dialog .modal-content {
    background-color: rgb(55, 179, 114, 0.9) !important;
    border-radius: 25px !important;
  }
  .modal-body {
    margin: 30px auto;
    padding: 1.4rem !important;
    width: 100%;
    .upload-option-wrapper {
      display: grid;
      grid-template-columns: 60% 40%;
      column-gap: 20px;
      width: 100%;
      justify-content: center;
      .upload-option {
        padding-top: 1em !important;
        padding-bottom: 1em !important;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
      }
      .upload-camera {
        .option-icon {
          color: #05b36d;
          font-size: 60px;
        }
        b {
          color: #05b36d;
        }
      }
      .upload-file {
        .option-icon {
          color: rgb(112, 112, 112);
          font-size: 60px;
        }
        b {
          color: rgb(112, 112, 112);
        }
      }
    }
  }
}
///
.bottom-btns {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 30px;
}
.car-title {
  margin-left: 22px;
}
.photo-upload-content {
  text-align: center;
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
    }
  }
  .photo-div {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 10px;
  }

  .photo-div ol {
    width: 100%;
    position: relative;
  }
  ol,
  ul {
    list-style: none;
  }
  .uploaded-fail {
    cursor: default !important;
  }
  .photo-div ol li:first-child {
    width: 98%;
    height: calc(66vw - 50px);
    img:not(.edit-photo, .upload-fail-icon) {
      height: calc(66vw - 50px);
    }
    .edit-photo {
      height: 40px;
      width: 40px;
      bottom: 50px;
    }
    .edit-photo.left {
      float: left;
      left: 5px !important;
    }
    .edit-photo.right {
      float: right;
      right: 5px !important;
    }
    .edit-photo.top {
      bottom: calc(66vw - 55px);
    }
    .edit-photo.bottom-left {
      left: -34px !important;
    }
    .image-title {
      position: absolute;
      top: 0;
      right: -2px;
      color: white;
      background-color: #05b36d;
      border-radius: 48px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 20px;
    }
    .upload-fail-text {
      .upload-fail-icon {
        width: 25px !important;
        height: 25px !important;
      }
      p {
        font-size: 16px !important;
      }
    }
  }
  .photo-div ol li:nth-child(2),
  .photo-div ol li:nth-child(3) {
    margin-top: 30px !important;
  }
  .photo-div ol li {
    float: left;
    width: calc(50% - 0.5rem);
    height: calc(30vw - 0.5rem);
    min-width: 150px;
    overflow: hidden;
    cursor: move;
    border-radius: 0.5rem;
    img:not(.edit-photo, .upload-fail-icon) {
      height: calc(30vw - 0.5rem);
      object-fit: cover;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }
    .img-onUpload {
      opacity: 50%;
    }
    .image-title {
      position: absolute;
      top: 0;
      right: -2px;
      color: white;
      background-color: #05b36d;
      border-radius: 48px;
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
    }
  }
  .photo-div ol li:not(:first-child) {
    .upload-fail-text {
      width: 150px;
      justify-content: center;
    }
  }
  .edit-photo {
    z-index: 100;
    position: relative;
    height: 25px;
    width: 25px;
    bottom: 29px;
    cursor: pointer;
  }
  .edit-photo.left {
    float: left;
    left: 5px !important;
  }
  .edit-photo.right {
    float: right;
    right: 5px !important;
  }
  .edit-photo.top {
    bottom: calc(30vw - 0.5rem - 5px);
  }
  .edit-photo.bottom-left {
    left: -19px !important;
  }
  .photo-upload {
    background-color: white;
  }
  .drop-area {
    margin-top: 20px;
  }
  .photo-upload-header {
    background-color: #e4f6ee;
    text-align: center;
    font-weight: 900;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .upload-btn {
    width: 100%;
    background-color: #e4f6ee !important;
    color: #05b36d !important;
    border-color: #05b36d !important;
  }
  .upload-item {
    background-color: #eee;
    border: 1px solid #05b36d !important;
    background-image: url(~@/assets/images/icons/ic_photo_upload.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2rem;
    cursor: pointer !important;
    overflow: hidden;
    display: inline-block;
    .photo-text {
      display: none;
    }
    .cover-text {
      display: none;
    }
    .cover-description-text {
      display: none;
    }
  }
  .img-upload-fail {
    background-color: #eee;
  }
  .upload-fail-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .upload-fail-icon {
      width: 14px !important;
      height: 14px !important;
    }
    p {
      color: #D20319 !important;
      font-size: 11px !important;
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
  .img-upload-progress {
    z-index: 999;
    position: absolute;
    bottom: 10px;
    width: 90%;
    left: 5%;
    height: 2%;
  }
  #drag-files,
  .upload-item input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  .photo-div ol li.upload-item:first-child {
    background-size: 3rem;
    background-position: right 50% bottom 70%;
    background-image: url(~@/assets/images/icons/ic_photo_upload.png);
    .photo-text {
      display: inline-block;
      border: none;
      border-radius: 20px !important;
      outline: none;
      background-color: rgba(223, 223, 223, 0.767);
      font-family: 'Avenir Next LT W05 Regular' !important;
      color: #000;
      margin-top: -2%;
      margin-bottom: 0.25rem;
      padding: 7px 14px;
    }
    .cover-text {
      font-family: 'Avenir Next LT W05 Thin';
      color: #05b36d;
    }
    .cover-description-text {
      display: block;
      color: #5E605F;
      width: 80%;
      margin: 0 auto;
    }
  }
  .not-upload-item {
    background-color: #eee;
  }
  .upload-item-no-border {
    @extend .upload-item;
    border: none !important;
  }
  .onDrop {
    background-color: #eff3fe !important;
    border: 2px dashed #276ef1 !important;
    border-radius: 15px;
    z-index: 9999 !important;
  }
  .photo-description {
    font-size: 16px;
    text-align: center;
    color: #3AAC5D;
    border: 1px solid #0AB76D;
    background: #E1F6ED;
    box-sizing: border-box;
    border-radius: 10px;
    width: 98%;
    max-width: calc(1000px - 2rem);
    margin: 10px auto;
    font-family: "Avenir Next LT W05 Regular";
    padding: 5px 0;
    margin-top: 12px;
  }
  .alert {
    .alert-container {
      img {
        width: 30px;
      }
    }
  }
  .alert-dismissible {
    width: 98%;
    font-weight: bold !important;
    font-family: Avenir Next LT W05 Regular !important;
    border-radius: 0.5rem !important;
    .close {
      top: 20% !important;
      transform: translateY(-50%) scale(1.25);
    }
    button {
      font-weight: bold !important;
      font-family: Avenir Next LT W05 Regular !important;
      border-radius: 0.5rem !important;
    }
  }
  .alert-danger {
    color: #FF6868 !important;
    background-color: #FFE4E4 !important;
    border-color: #FF6868 !important;
    margin: 10px auto;
  }
  .col {
    padding: 0;
  }
  .min-w-222 {
    min-width: 222px;
  }
  .photo-div-text-above {
    border: 1px solid #0AB76D;
    color: #3AAC5D;
    border-radius: 8px;
    font-family: 'Avenir Next LT W05 Regular';
    font-size: 14px;
    padding: 5px 0;
    margin-top: 8px;
  }
  
  .display-when-portrait {
    display: block;
  }
}

@media screen and (orientation: landscape) {
  .display-when-portrait {
    display: none !important;
  }

  .camera-modal {
    .camera-screen {
      #camera-outlay {
        top: 0;
        left: 0;
        height: 100%;
        width: calc(100% - 90px);
        transform: none;
        img {
          width: calc(100vw - 120px);
          height: 90%;
          transform: none;
          padding-right: 60px;
          padding-top: 40px;
        }
      }
      .function-section {
        width: 90px;
        height: 100%;
        background: rgba(37, 43, 47, 0.5);
        backdrop-filter: blur(22px);
        right: 0;
        left: unset;
        .shoot-btn {
          bottom: 50%;
          left: unset !important;
          transform: translate(-50px, 50%);
        }
        .button-wrapper {
          flex-direction: column-reverse;
          .under-btn {
            display: flex;
            flex-direction: column;
            .btn:first-child {
              margin: 0px 10px;
            }
            .btn:last-child {
              margin: 10px;
            }
            .body-type-btn, .help-btn {
              transform: none;
            }
          }
          .upper-btn {
              .close-btn {
                transform: none;
              }
          }
        }
      }
      .body-type-dropdown {
        transform: rotate(0deg) translateX(calc(-50% - 43px));
        top: unset;
        left: 50%;
        bottom: -40px;
        ul {
          max-height: 300px;
          overflow-y: auto;
          li {
            height: 40px;
            padding: 10px 20px;
            &:last-child {
              padding-bottom: 50px;
            }
          }
        }
      }
      .photo-frame-wrapper {
        .photo-frame-dropdown {
          top: -3px;
          transform: translateX(calc(-50% - 60px));
          right: unset;
          left: 50%;
          flex-direction: column;
          .photo-image-list-wrapper {
            border-bottom: none;
            border-left: 2px solid #ffffff;
            transform: none;
            ul {
              li {
                .option {
                  .frame-name {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          @media screen and (max-height: 374px) {
            .photo-image-list-wrapper {
              height: 230px;
            }
          }
          .photo-frame-header {
            height: 50px;
            text-orientation: unset;
            -ms-writing-mode: tb-rl;
            writing-mode: unset;
            width: 330px;
            border-radius: 0 0 22px 22px;
            .screenshot-title {
              width: 100%;
              padding: 0px 10px;
              line-height: 40px;
            }
            .select-angle-btn {
              padding: 0px 20px;
              border-left: 2px solid #ffffff;
              border-top: none;
            }
          }
        }
      }
    }
    .confirm-option-wrapper {
      left: 50%;
      bottom: -20px !important;
      transform: translateX(calc(-50% - 40px));
      top: unset !important;
      flex-direction: row;
      height: 65px;
      width: 400px;
      .confirm-option {
        padding-top: 15px !important;
        padding-bottom: 25px !important;
        b {
          color: #fff;
          font-size: 17px;
        }
      }
      .use-this-photo, .take-again {
        text-orientation: unset;
        writing-mode: unset;
        width: 170px;
        height: 65px;
        .confirm-text {
          margin: 0 10px 0 0;
        }
        .confirm-icon {
          transform: none;
        }
      }
      .take-again {
        padding-right: 10px;
        width: 190px;
      }
      .overlay {
        top: unset;
        left: -30px;
      }
    }
  }
}

.nudge {
  .heading{
    margin-bottom: 0;
    font-weight: bold !important;
  }
  .alert-heading {
    font-weight: normal !important;
    color: #463209;
    background-color: #fff6ed;
    border: 1px solid #F5CE84;
    box-sizing: border-box;
    border-radius: 10px;
    width: 98%;
    margin: 10px auto;
    padding: 10px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin-bottom: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }
  }
}

@media screen and(min-width: 900px) {
  .car-title {
    margin-left: 70px !important;
  }
  .photo-upload {
    .photo-description {
      width: 94%;
      padding-left: 0 !important;
      padding-right: 0 !important;

      div {
        margin-top: 0 !important;
      }
    }
    .drop-area {
      margin-top: 0;

      .upload-text {
        display: none;
      }
      .drop-text {
        position: absolute;
        display: block;
        color: #276ef1;
        font-family: Avenir Next LT W05 Regular !important;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        transform: translateX(-50%);
      }
    }
    .btn-group-wrapper {
      margin-top: 0.5rem !important;
    }
    .forward-btn {
      width: auto;
      position: absolute;
      right: 190px;
      bottom: 55px;
      background-color: #ffffff !important;
    }
    .alert-dismissible {
      width: 94%;
      .close {
        top: 5% !important;
        transform: scale(1.5) !important;
        z-index: 100000;
      }
    }
    .alert-danger {
      margin: 0;
    }
    .photo-div ol li {
      width: 152px;
      height: 92px;
      min-width: unset;
      z-index: 100000;
      position: relative;

      .image-title {
        font-size: 10px !important;
        padding: 5px 5px;
      }

      img:not(.edit-photo, .upload-fail-icon) {
        height: 92px !important;
      }

      .edit-photo {
        width: 18px;
        height: 18px;
        bottom: 23px;
      }

      .edit-photo.top {
        bottom: calc(92px - 5px);
      }

      .edit-photo.bottom-left {
        left: -13px !important;
      }

      &:first-child {
        width: 472px;
        height: 291px;

        .image-title {
          font-size: 16px !important;
        }

        img:not(.edit-photo, .upload-fail-icon) {
          height: 291px !important;
        }

        .edit-photo {
          width: 30px;
          height: 30px;
          bottom: 35px;
        }

        .edit-photo.top {
          bottom: calc(291px - 5px);
        }

        .edit-photo.right {
          right: 7px !important;
        }

        .edit-photo.bottom-left {
          left: -23px !important;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0.25rem !important;
      }

      &.upload-item:first-child,
      &.upload-item-no-border:first-child {
        background-position: right 50% bottom 65%;
        background-size: 5rem;
      }
    }
    .photo-div .wide-list {
      width: auto !important;
      padding: 0 1rem !important;
      margin-left: 0.8rem;
    }
  }

  #drag-files,
  .upload-item input[type="file"] {
    z-index: 99999;
  }

  .nudge {
    .alert-dismissible {
      .close {
        top: 20% !important;
      }
    }
    .heading {
      font-size: 20px;
    }
    .alert-heading {
      font-weight: bold !important;
      font-size: 16px;
    }
  }
}

@media screen and(min-width: 768px) {
  .car-title {
    margin-left: 66px;
  }
  .photo-upload-content {
    .btn-group-wrapper.custom-padding-1 {
      // 100vh - header - stepper - content - button group height
      padding-bottom: max(calc(100vh - 75px - 102px - 520px - 64px), 40px);
    }

    .btn-group-wrapper.custom-padding-2 {
      padding-bottom: max(calc(100vh - 75px - 102px - 590px - 64px), 40px);
    }

    .btn-group-wrapper.custom-padding-3 {
      padding-bottom: max(calc(100vh - 75px - 102px - 625px - 64px), 40px);
    }
  }
}
</style>
